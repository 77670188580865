<template>
    <div class="access-denied text-center">
        <h1>접근 권한이 없습니다</h1>
        <p>이 페이지에 접근할 권한이 없습니다. 관리자에게 문의하시기 바랍니다.</p>
        <button class="btn-back" @click="$router.go(-1)">뒤로가기</button>
    </div>
</template>

<script>
export default {
    name: 'AccessDenied',
};
</script>

<style scoped>
.access-denied {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

p {
    font-size: 1.2rem;
    margin-bottom: 40px;
}

.btn-back {
    width: 152px;
    height: 40px;
    background: #0078AF;
    border-radius: 4px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.0015em;
    color: #FFFFFF;
}
</style>
